/*********************************************/
/*          CUSTOMIZED DEFAULTS         */
/*********************************************/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700|Poppins:100,200,300,400,500,600,700,800,900&display=swap");
@import "falcon_template/assets/scss/theme";
@import "falcon_template/assets/scss/user";
@import "override/assets/scss/user";
@import "override/assets/scss/analyze-switch";

/*********************************************/
/*          CUSTOM STYLES               */
/*********************************************/
@media (min-width: 1200px) {
  .extra-margin-when-large {
    margin-left: 1rem !important;
  }
}

.timeline-starts {
  word-break: break-word;
}
.word-wrap {
  word-break: break-word;
}

.b7{
  font-size: 0.7rem !important;
}

.b7 button {
  font-size: 0.7rem !important;
  color: $accordion-button-color;
}

.b7 button:not(.collapsed) {
  box-shadow: unset;
}

.filter-dropdown-button{
  display: inline !important;
}

.timestamp-control {
  vertical-align: 0.35em !important;
  display: inline-flex !important;
}

.timestamp-close-button {
  border: 1px solid var(--falcon-input-border-color);
}

.h6-vertical-align {
  vertical-align: 0.2em !important;
}

.timestamp-py {
  padding-top: 0.05rem !important;
  padding-bottom: 0.05rem !important;
}

.custom-width{
  width: unset !important;
  flex: unset !important;
}

.outcome-button{
  text-align: left;
}

.truncate-title{
  max-width: 110%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.truncate-header{
  max-width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: bottom;
}

.spinner-border-xs {
  width: 1rem;
  height: 1rem;
  border-width: 0.19em;
}

.btn-sm-i-no-s {
  padding-top: 0.1875rem !important;
  padding-right: 0.75rem !important;
  padding-bottom: 0.1875rem !important;
  font-size: 0.875rem !important;
  border-radius: 0.2rem !important;
}

//fonts
@font-face {
  font-family: 'Sifonn-Pro';
  src: local('Sifonn-Pro'), url(override/assets/fonts/Sifonn-Pro.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.logo-text {
  font-family: Sifonn-Pro, Arial, serif;
  font-weight: black;
  color: $xenon-orange;
  letter-spacing: -1px;
  transform: scale(1.05, 1.10);
}
