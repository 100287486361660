@import 'bootstrap/scss/_functions';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/variables';

/* insprired by:
Project:	Toggle Switchy
Version:	1.14
Updated:	8/20/2020
Author:		Adam Culpepper | @adamculpepper
Website:	https://toggleswitchy.com
Docs:		https://github.com/adamculpepper/toggle-switchy
Issues:		https://github.com/adamculpepper/toggle-switchy/issues
Releases:	https://github.com/adamculpepper/toggle-switchy/releases
*/

/* Colors: Default (blue) */
.analyze-toggle {color:#fff;}
.analyze-toggle > input + .toggle:before {
  content:'ANALYZING';
  vertical-align: 3%;
  padding-left: 0.5rem;
}
.analyze-toggle > input + .toggle:after {
  content:'ANALYZE';
  vertical-align: 3%;
  margin-left: -1.75rem;
}
.analyze-toggle > input + .toggle > .switch {background:#fff;}
.analyze-toggle > input + .toggle + .label {color:#000;}
.analyze-toggle > input:checked + .toggle {background:$form-check-input-checked-bg-color;}
.analyze-toggle > input:not(:checked) + .toggle {background:#ccc;}
.analyze-toggle > input:checked + .toggle > .switch {border:3px solid $form-check-input-checked-border-color;}
.analyze-toggle > input:not(:checked) + .toggle > .switch {border:3px solid #ccc;}
.analyze-toggle > input:focus + .toggle,
.analyze-toggle > input:active + .toggle {box-shadow:0 0 5px 3px rgba(0, 119, 200, 0.50);}

/* Rounded switch corners */
.analyze-toggle > input + .toggle {border-radius:4px;}
.analyze-toggle > input + .toggle .switch {border-radius:6px;}


.analyze-toggle {display:inline-flex; align-items:center; user-select:none; position:relative; vertical-align:middle; margin-bottom:0;}
.analyze-toggle:hover {cursor:pointer;}
.analyze-toggle > input {position:absolute; opacity:0;}
.analyze-toggle > input + .toggle {align-items:center; position:relative;}
.analyze-toggle > input + .toggle {overflow:hidden; position:relative; flex-shrink:0;}
.analyze-toggle > input[disabled] + .toggle {opacity:0.5;}
.analyze-toggle > input[disabled] + .toggle:hover {cursor:not-allowed;}
.analyze-toggle > input + .toggle {width:100%; height:100%; margin:0; cursor:pointer;}
.analyze-toggle > input + .toggle > .switch {display:block; height:100%; position:absolute; right:0; top:0; z-index:3; box-sizing:border-box;}

/* Show / Hide */
.analyze-toggle > input + .toggle:before {opacity:0;}
.analyze-toggle > input:checked + .toggle:before {opacity:1;}
.analyze-toggle > input:checked + .toggle:after {opacity:0;}

/* Transitions */
.analyze-toggle > input + .toggle {transition:background 200ms linear, box-shadow 200ms linear;}
.analyze-toggle > input + .toggle:before,
.analyze-toggle > input + .toggle:after {transition:all 200ms linear;}
.analyze-toggle > input + .toggle > .switch {transition:right 200ms linear, border-color 200ms linear;}
/* //////////////////////////
CORE STYLES ABOVE - NO TOUCHY
////////////////////////// */

/* Size: Extra Small */
.analyze-toggle> input + .toggle							{width:75px; height:20px;}
.analyze-toggle> input + .toggle > .switch					{width:20px;}
.analyze-toggle> input + .toggle:before,
.analyze-toggle> input + .toggle:after						{font-size:0.5rem;}
.analyze-toggle> input:not(:checked) + .toggle > .switch	{right:calc(100% - 20px);}

/* Style: Rounded */
.analyze-toggle> input + .toggle,
.analyze-toggle> input + .toggle > .switch			{border-radius:50px;}
.analyze-toggle> input + .toggle:before				{right:50%;}
.analyze-toggle> input + .toggle:after				{left:50%;}
