@import 'bootstrap/scss/_functions';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/variables';
@import 'falcon_template/assets/scss/user';

//
//
// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.


.timeline-vertical {
  .timeline-item-with-progress {
    @include media-breakpoint-up($timeline-vertical-breakpoint) {
      padding-right: 6rem !important;
    }
  }
}

.table td.fit,
.table th.fit {
  white-space: nowrap;
  width: 1% !important;
}

.bg-info-light {
  background-color:rgba(76,192,248,0.15);
}

$xenon: #052F6B !default;
$xenon-orange: #E77208 !default;

$theme-colors: map-merge(
                (
                        'xenon-orange': $xenon-orange,
                ),
                $theme-colors
);

$theme-colors-soft: map-merge(
                (
                        'xenon-orange': tint-color($xenon-orange, 88%),
                ),
                $theme-colors-soft
);

$theme-dark-colors: map-merge(
                (
        'xenon-orange': $xenon-orange,
                ),
                $theme-dark-colors
);


$theme-dark-colors-soft: map-merge(
        (
                'xenon-orange': shift-color($xenon-orange, 70%),
        ),
        $theme-dark-colors-soft
);