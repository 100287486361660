.react-select__control {
  min-height: unset !important;
  padding-left: 1rem;
  &.react-select__control--is-focused {
    border-color: $form-select-focus-border-color !important;
    outline: 0;
    box-shadow: $form-select-focus-box-shadow !important;
  }
  background-color: $input-bg !important;
  border: $form-select-border-width solid $form-select-border-color !important;
  @include box-shadow($form-select-box-shadow);

  .react-select__value-container {
    padding: 0;
  }

  .react-select__placeholder {
    color: $gray-400;
    margin: 0;
  }
  @include hover-focus {
    outline: none !important;
  }
  .react-select__input-container {
    margin: 0;
  }
  .react-select__value-container {
    &.react-select__value-container--has-value {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }
  .react-select__multi-value {
    border-radius: $border-radius;
    background-color: var(--#{$variable-prefix}200) !important;
    .react-select__multi-value__remove {
      background-color: var(--#{$variable-prefix}300) !important;
      border-radius: 0 2px 2px 0 !important;
    }
  }
  .react-select__multi-value__label {
    color: var(--#{$variable-prefix}body-color) !important;
    font-size: 12px;
  }
}
.react-select__menu {
  .react-select__option {
    padding-left: $card-spacer-x;
    padding-right: $card-spacer-x;
    @include hover-focus {
      color: $white !important;
    }
  }
}

.react-select__menu {
  background-color: $input-bg !important;
}

.react-select__option {
  background-color: $input-bg !important;
  color: $gray-400;

  @include hover-focus {
    background-color: $primary !important;
  }
}

.react-select__indicator-separator {
  background-color: var(
    --#{$variable-prefix}react-select-indicator-bg
  ) !important;
}

.react-select__indicator svg {
  color: var(--#{$variable-prefix}react-select-indicator-bg) !important;
}
